.hero-bg {
  background-image: url('../../../public/bbblurry.svg') !important;
  background-size: cover;
  height: 100vh;
}

.hero-container {
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 50%;
  display: flex;
  flex-direction: column;
}

.hero-text {
  font-family: Poppins;

  margin: auto;
  width: 50%;
  text-align: center;
}


